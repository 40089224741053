<template>
  <v-container fluid>
    <div class="text-right mb-2 mt-5">
      <v-btn color="secondary" outlined @click="refresh">{{
        $vuetify.lang.t("$vuetify.refresh")
      }}</v-btn>
    </div>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">{{
        $vuetify.lang.t("$vuetify.applications")
      }}</v-flex>
      <v-flex
        class="tableSearch px-4 pt-2"
        style="display: flex; align-items: center"
      >
        <v-layout row wrap xs12>
          <v-form style="width: 100%" class="px-3" @submit.prevent="searchData">
            <v-row align="center">
              <v-col cols="12" md="5">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.search')"
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  :items="searchable_items"
                  :item-text="'text'"
                  :item-value="'search'"
                  v-model="search_field"
                  :label="$vuetify.lang.t('$vuetify.search_from_column')"
                  disable-initial-sort
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <div style="display: flex; align-items: center">
                  <v-btn
                    type="submit"
                    outlined
                    color="secondary"
                    class="caption ma-0"
                    block
                    >{{ $vuetify.lang.t("$vuetify.search") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-layout>
      </v-flex>
    </div>
    <v-data-table
      :headers="tableHeader"
      :items="formatedApplicationList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      @pagination="someChange"
      item-key="id"
      height="530"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1"
      @click:row="rowClicked"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "application",
  data() {
    return {
      loading: false,
      applications: [],
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      search_field: "candidate,first_name",
      search: "",
    };
  },
  computed: {
    ...mapState(["profileLinks"]),
    ...mapGetters(["SHOW_AI_SCORE"]),
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    tableHeader() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job"),
          align: "start",
          value: "title",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ai_score"),
          align: "start",
          value: "ai_score",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status"),
          value: "status",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.applied_on"),
          value: "date",
          sortable: true,
        },
      ];
    },
    searchable_items() {
      return [
        {
          search: "candidate,first_name",
          text: "Name",
        },
        {
          search: "jobPost,title",
          text: "Job",
        },
        {
          search: "status",
          text: "Status",
        },
      ];
    },
    formatedApplicationList() {
      const applications = this.applications.map((application) => {
        const {
          _id,
          source,
          status,
          candidate_id,
          job_post,
          candidate,
          ai_score,
          created_at,
        } = application;

        let title = "No Job Posted";

        if (job_post) {
          title = job_post.title;
        }

        return {
          id: _id,
          name: candidate && candidate.full_name,
          title,
          source,
          status,
          ai_score: this.SHOW_AI_SCORE && ai_score ? ai_score : 0,
          candidate_id,
          date: UTC_TO_LOCAL(created_at, "lll"),
        };
      });

      // if (this.$route.query.sortBy && this.$route.query.sortBy == "ai") {
      //   return applications.sort((a, b) => b.ai_score - a.ai_score);
      // }

      return applications;
    },
    whereHas() {
      let whereHas = null;
      let like = null;
      if (this.search != "") {
        let fields = this.search_field.split(",");

        if (fields.length > 1) {
          whereHas = {
            key: fields[0],
            value: fields[1] + "," + this.search,
          };
          return JSON.stringify(whereHas);
        } else {
          like = {
            key: this.search_field,
            value: this.search,
          };
          return JSON.stringify(like);
        }
      } else {
        return null;
      }
    },
    sortByAi() {
      return this.$route.query.sortBy && this.$route.query.sortBy == "ai";
    },
  },
  methods: {
    ...mapMutations(["SET_PROFILE_LINKS"]),
    rowClicked(item) {
      const links = [...this.profileLinks];
      const index = links.findIndex((profile) => profile.id === item.id);
      if (index === -1) links.push(item);
      this.SET_PROFILE_LINKS(links);
      const routeData = this.$router.resolve(`/application/${item.id}`);
      window.open(routeData.href, "_blank");
    },
    async fetchApplications() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;

        let url = `/application?w=candidate,jobPost,notes,events,emails,events,logs,referal,calendar&desc=created_at&p=${currentPage}&limit=${limit}`;
        if (this.whereHas) {
          let key = "whereHas";
          if (this.search_field === "status") key = "like";
          url = `/application?w=candidate,jobPost,notes,events,emails,events,logs,referal,calendar&desc=created_at&p=${currentPage}&limit=${limit}&${key}=${this.whereHas}`;
        }

        if (!this.sortByAi) {
          const resp = await this.axios.get(url);
          const { data, count } = resp.data;
          this.applications = data;
          this.totalCount = count;
        } else {
          const resp = await this.axios.get("/top/ai_score");
          const { data } = resp.data;
          this.applications = data;
          this.totalCount = data.length;
          this.limit = data.length;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    someChange(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchApplications();
      }
    },
    searchData() {
      this.currentPage = 1;
      this.fetchApplications();
    },
    refresh() {
      this.search_field = "candidate,first_name";
      this.search = "";
      if (this.$route.query.filter || this.sortByAi) {
        this.$router.replace("/application");
      }
      this.fetchApplications();
    },
  },

  created() {
    if (this.$route.query.filter) {
      this.search_field = "status";
      this.search = this.$route.query.filter;
    }
    this.fetchApplications();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
